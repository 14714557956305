<template>
  <Header/>
  <div class="container is-max-desktop my-bookings">
    <h1>Administrator dashboard</h1>
    <h2>Invitationslinks</h2>
    <div v-if="invites.length > 0">
        <p>Kopiér og send nedenstående links til nye brugere</p>
        <div class="table-container">
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <th>Konto</th>
                    <th>Link</th>
                </thead>
                <tbody>
                <tr v-for="invite in invites" :key="invite.id">
                    <td>{{ invite.accountName }}</td>
                    <td>
                      <input class="input" type="text" :value="[siteRoot] + ['invite/'] + [invite.hash]">
                      
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="invites.length == 0">
      <p>Der er ingen invitationslinks.</p>
    </div>
    <LoginStatus />
  </div>
  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LoginStatus from '@/components/LoginStatus.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
        global,
        siteRoot: process.env.VUE_APP_SITE_ROOT,
        apiRoot: process.env.VUE_APP_API_ROOT
    }
  },
  data() {
    return {
      invites: []
    }
  },
  components: {
    Header,
    Footer,
    LoginStatus,
  },
  created() {
      this.invitesLoad();
  },
  methods: {
      invitesLoad() {
        axios.get(process.env.VUE_APP_API_ROOT + '/invites.php?mode=list')
        .then(response => {
            this.invites = response.data.invites;            
        })
        .catch(function (error) {
        
        });
      }
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>